<template>
  <div>
     <div class="login">
       <h1 class="loginlogo" @click="goPageByName('mobileMain')"><img src="@/assets/img/play_double.svg" /></h1>
       <div class="login-con">
         <div class="login-name">
            <h2><img src="@/assets/img/icon_dia.svg" />{{$t('front.gnb.login')}}</h2>
         </div>
         <div class="login-in">
           <!--h2>{{$t('front.gnb.hello')}}</h2>
           <span>{{$t('front.gnb.login')}}</span-->
           <div class="input-wrap">
              <input class="logininput" type="text" :placeholder="$t('front.common.memId')" v-model="model.memId"/>
           </div>
           <div class="input-wrap">
              <input class="logininput pw" type="password" ref="search" :placeholder="$t('front.common.password')" v-model="model.memPass" @keyup.enter="onSubmit"/>
           </div>
           <section class="captcha-box">
             <VueClientRecaptcha
                 :value="inputValue"
                 :count="4"
                 chars="12345"
                 :hideLines="true"
                 custom-text-color="black"
                 @getCode="getCaptchaCode"
                 @isValid="checkValidCaptcha"
             >
               <template>
                 <span style="color: blue">with Custom Text Or Icon</span>
               </template>
             </VueClientRecaptcha>
             <div class="code">
                <h2>보안문자입력</h2>
                <input
                    v-model="inputValue"
                    placeholder=""
                    class="input"
                    type="text"
                />
             </div>
           </section>
           <!--a class="findbtn">{{$t('front.common.memId')}}/{{$t('front.common.password')}} {{$t('front.common.find')}}</a-->
           <div class="loginnbtn-wrap"><a class="loginnbtn" @click="onSubmit">{{$t('front.login.loginButton')}}</a></div>
           <p class="joinnbtnwrap">{{$t('front.login.notJoin')}} <a class="joinnbtn" @click="goPageByName('mobileJoin')">{{$t('front.common.signup')}}</a></p>
         </div>
       </div>
     </div>
  </div>
</template>

<script>
import '@/styles_mobile/login.css'
import { signIn } from '@/api/member'
import { setAuthData } from '@/libs/auth-helper'
import store from '@/store'

import VueClientRecaptcha from 'vue-client-recaptcha'

export default {
  name: 'login',
  components: {
    VueClientRecaptcha
  },
  data () {
    return {
      model: {
        memId: '',
        memPass: ''
      },
      data: {
        captchaCode: null,
        isValid: false
      },
      inputValue: null,
      focused: false
    }
  },
  computed: {
    canSubmit () {
      return this.model.memId && this.model.memPass
    }
  },
  methods: {
    getCaptchaCode (value) {
      this.data.captchaCode = value
    },
    checkValidCaptcha (value) {
      this.data.isValid = value
    },
    getMemId (value) {
      this.model.memId = value
    },
    getMemPass (value) {
      this.model.memPass = value
    },
    setReloadCaptcha () {
      setTimeout(() => {
        if (document.querySelector('.vue_client_recaptcha_icon')) {
          document.querySelector('.vue_client_recaptcha_icon').click()
        }
      }, 500)
    },
    async onSubmit () {
      if (!this.data.isValid) {
        await this.onAlert('warningart', '자동 입력 방지 코드를 확인해주세요.')
        setTimeout(() => {
          if (document.querySelector('.vue_client_recaptcha_icon')) {
            document.querySelector('.vue_client_recaptcha_icon').click()
          }
        }, 500)

        return false
      }

      let reloadCaptcha = false

      if (this.model.memId === '') {
        this.onAlert('warningart', 'front.signin.emptyMemId')
        reloadCaptcha = true
      }
      if (this.model.memPass === '') {
        this.onAlert('warningart', 'front.signin.emptyMemPass')
        reloadCaptcha = true
      }

      if (!this.canSubmit) {
        reloadCaptcha = true
      }

      if (reloadCaptcha) {
        this.setReloadCaptcha()
        return false
      }

      /*
      * api/member.js 참고
      */
      signIn(this.model).then(async response => {
        const result = response.data
        const data = result.data
        if (result.resultCode === '0') {
          setAuthData(data)
          store.commit('setUserData', data.member)
          store.dispatch('storeCommonCode').then(commonCode => {
            this.replacePageByName('mobileMain')
          })
        } else if (result.resultCode === 'U001' || result.resultCode === 'LX02') {
          await this.onCheck('err.' + result.resultCode)
          this.setReloadCaptcha()
        } else {
          await this.onCheck('로그인정보가 올바르지 않습니다. 확인해주세요.')
          this.setReloadCaptcha()
        }
      }).catch(err => {
        console.error('login err : ', err.message)
        if (err.message === 'no member') {
          // alert('로그인정보가 올바르지 않습니다. 확인해주세요.')
          this.onCheck('로그인정보가 올바르지 않습니다. 확인해주세요.')
          this.setReloadCaptcha()
        } else {
          alert(err.message)
        }
      })
    }
  }
}
</script>
<style scoped>
.captcha-box {display: flex;align-items: center;border: solid 1px #cef528;background: #fff;}

.captcha-box .vue_client_recaptcha {width: 100%;display: flex;align-items: center;flex-direction: row-reverse;}

/deep/ .captcha-box .vue_client_recaptcha .vue_client_recaptcha_icon {margin-left: -20px;}

/deep/ #captcha_canvas {width: 100px;height: 35px;object-fit: contain;object-position: center;}
/deep/ .vue_client_recaptcha_icon img {width: 40px;height: 40px;object-fit: contain;object-position: center;}

.code {position: relative;width: 100%;height: 65px;font-size: 15px;border-left: 1px solid #cef528;box-sizing: border-box;color: #fff;background: #131313;display: flex;flex-direction: column;align-items: center;justify-content: center;}
.code > h2 {width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;}
.captcha-box .input {width: 100%;height: 100%;border: 0;border-radius: 0;border-top: 1px solid #cef528;box-sizing: border-box;background: none;padding: 0;text-align: center;color: #fff;}
</style>
<style scoped src="@/styles_mobile/login.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
